export default [
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      resource: 'User',
      action: 'manage',
    },
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'User',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/list',
    name: 'apps-styles-list',
    component: () => import('@/views/apps/style/styles-list/StylesList.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/view/:id',
    name: 'apps-styles-view',
    component: () => import('@/views/apps/style/styles-view/StylesView.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/edit/:id',
    name: 'apps-styles-edit',
    component: () => import('@/views/apps/style/styles-edit/StylesEdit.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/copy/:id',
    name: 'apps-styles-copy',
    component: () => import('@/views/apps/style/styles-copy/StylesCopy.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/:id/add-material/:category',
    name: 'apps-styles-add-material',
    component: () => import('@/views/apps/style/material/MaterialAdd.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/:id/edit-material/:material',
    name: 'apps-styles-edit-material',
    component: () => import('@/views/apps/style/material/MaterialEdit.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/materials/list',
    name: 'apps-materials-list',
    component: () => import('@/views/apps/material/materials-list/MaterialsList.vue'),
    meta: {
      resource: 'Material',
      action: 'manage',
    },
  },
  {
    path: '/apps/materials/edit/:id',
    name: 'apps-materials-edit',
    component: () => import('@/views/apps/material/materials-edit/MaterialsEdit.vue'),
    meta: {
      resource: 'Material',
      action: 'manage',
    },
  },
  {
    path: '/apps/sample-requests/list',
    name: 'apps-sample-requests-list',
    component: () => import('@/views/apps/sample-request/sample-requests-list/SampleRequestsList.vue'),
    meta: {
      resource: 'SampleRequest',
      action: 'manage',
    },
  },
  {
    path: '/apps/sample-requests/edit/:id',
    name: 'apps-sample-requests-edit',
    component: () => import('@/views/apps/sample-request/sample-requests-list/SampleRequestEdit.vue'),
    meta: {
      resource: 'SampleRequest',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/:id/add-embellishment/:category',
    name: 'apps-styles-add-embellishment',
    component: () => import('@/views/apps/style/embellishment/EmbellishmentAdd.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/styles/:id/edit-embellishment/:embellishment',
    name: 'apps-styles-edit-embellishment',
    component: () => import('@/views/apps/style/embellishment/EmbellishmentEdit.vue'),
    meta: {
      resource: 'Style',
      action: 'manage',
    },
  },
  {
    path: '/apps/suppliers/list',
    name: 'apps-suppliers-list',
    component: () => import('@/views/apps/supplier/suppliers-list/SuppliersList.vue'),
    meta: {
      resource: 'Supplier',
      action: 'manage',
    },
  },
  {
    path: '/apps/suppliers/edit/:id',
    name: 'apps-suppliers-edit',
    component: () => import('@/views/apps/supplier/suppliers-edit/SupplierEdit.vue'),
    meta: {
      resource: 'Supplier',
      action: 'manage',
    },
  },
  {
    path: '/apps/suppliers/:id/edit-core/:core',
    name: 'apps-suppliers-edit-core',
    component: () => import('@/views/apps/supplier/suppliers-edit/CoreEdit.vue'),
    meta: {
      resource: 'Supplier',
      action: 'manage',
    },
  },
]
