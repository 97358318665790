import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

export default class CustomJwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Override broken Response interceptor from @core/auth/jwt/jwtService
    this.axiosIns.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config
        // console.debug('error.response.status', error.response.status)
        if (error.response.status === 401) {
          if (originalRequest.url.includes(this.jwtConfig.refreshEndpoint)) {
            this.cleanTokensAndRedirectToHomepage()
            return Promise.reject(error)
          }
          if (originalRequest.url.includes(this.jwtConfig.loginEndpoint)) {
            return Promise.reject(error)
          }
          // console.debug('originalRequest.url', originalRequest.url)
          // console.debug('originalRequest.retry', originalRequest.retry)
          if (!originalRequest.retry) {
            originalRequest.retry = true
            await this.axiosIns
              .post(this.jwtConfig.refreshEndpoint, {
                refresh: this.getRefreshToken(),
              })
              .then(response => {
                if (response.status === 200) {
                  this.setToken(response.data.access)
                }
              })
            return this.axiosIns(originalRequest)
          }
        }
        return Promise.reject(error)
      },
    )
  }

  cleanTokensAndRedirectToHomepage() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

    localStorage.removeItem('userData')
    window.location.href = '/'
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
